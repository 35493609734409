import "babel-polyfill";
import axios from "axios";
import Vue from "vue/dist/vue.js";

require("./blog-post.js");

var blog = new Vue({
    el: "#blog",
    data: {
        posts: []
    },
    computed: {
        blogUrl: function () {
            return "https://datlab.eu/blog";
        },
        apiUrl: function () {
            return this.blogUrl + "/ghost/api/v3/content";
        },
        apiClientSecret: function () {
            return "b89dbfd64842478a86d35ee33a";
        }
    },
    mounted() {
        this.getLastPosts();
    },
    methods: {
        getApiRequestUrl: function (endpoint, params) {
            var query = [];
            params["key"] = this.apiClientSecret;
            Object.keys(params).forEach((i) => query.push(encodeURIComponent(i) + "=" + encodeURIComponent(params[i])));
            //blog.econlab.cz/ghost/api/v3/content/posts/?key=b89dbfd64842478a86d35ee33a
            https: return this.apiUrl + "/" + endpoint + "/?" + query.join("&");
        },
        getLastPosts: function () {
            var vm = this;

            var url = this.getApiRequestUrl("posts", {
                limit: 3,
                fields: "title,html,feature_image,url",
                filter: "tag:metodika-web",
                order: "published_at asc"
            });

            axios
                .get(url)
                .then(function (response) {
                    vm.posts = [];
                    vm.posts.push.apply(vm.posts, response.data.posts);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
});
