import Vue from "vue/dist/vue.js";

Vue.component("blog-post", {
    props: ["post", "blogUrl"],
    template:
        '<a class="blog-post" v-bind:href="post.url" target="_blank">' +
        "<div class=\"blog-post-image\" v-bind:style=\"{'backgroundImage': 'url(' + imageUrl + ')'}\"></div>" +
        '<p class="text-left">{{ post.title }}</p>' +
        '<p class="text-left">{{ textOverview }}</p>' +
        "</a>",
    computed: {
        textOverview: function () {
            return (
                this.post.html.replace(/<[^>]+>/g, "").substring(0, 150) + "..."
            );
        },
        imageUrl: function () {
            if (this.post.feature_image.startsWith("http")) {
                return this.post.feature_image;
            }

            return this.blogUrl + this.post.feature_image;
        },
    },
});
